// 查询用户是否签署领里公约
const signingOfContractDetailsUrl = `/serveApi/miniapi/neighborhood/signing-of-contract-details`;
// 获取合约内容
const getNeighborhoodConventionUrl = `/serveApi/miniapi/neighborhood/get-neighborhood-convention`;
// 签署领里公约
const signTheContractUrl = `/serveApi/miniapi/neighborhood/sign-the-contract`;
// 上传
const uploadUrl = `/ossApi/oss/endpoint/put-file`;

export {
  signingOfContractDetailsUrl,
  getNeighborhoodConventionUrl,
  signTheContractUrl,
  uploadUrl,
};
